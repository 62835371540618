import React from "react";
import Product from "./Product";
import Categories from "./Categories";
import Brand from "./Brand";
import Variant from "./Variant";
import Settings from "./Settings";
import ProductSubscription from "./ProductSubscription";
import { TabsGenerator } from "../../../../Common/";
import Languages from "./Languages";


const tabs = [
  {
    label: "Products",
    value: "product",
    component: <Product />,
  },
  {
    label: "Categories",
    value: "categories",
    component: <Categories />,
  },
  {
    label: "Brands",
    value: "brands_management",
    component: <Brand />,
  },
  {
    label: "Variants",
    value: "variant",
    component: <Variant />,
  },
  {
    label: "Product Subscription",
    value: "product_subscription",
    component: <ProductSubscription />,
  },
  {
    label: "Languages",
    value: "languages",
    component: <Languages />,
  },
  {
    label: "Settings",
    value: "settings",
    component: <Settings />,
  },
];

const ProductManagement = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="product" />
    </>
  );

};

export default ProductManagement;
