import React, { useEffect, useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import styles from "../Product/styles";
import "../Product/styles.css";
import { AddButton, EditButton, DeleteButton } from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import { AllocateButton } from "components/Common/Buttons";
import LanguageSelectionDialog from "./CheckBoxDialog"


const filterLabels = {
  brand_name: "brand",
  operator: "operator__business_name",
  status: "status",
};

const deviceTypeFields = ["brand_name", "operator","status"];

const Languages = () => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [globalLanguageList, setGlobalLanguageList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [crudLoader, setCrudLoader] = useState(false)

  const { enqueueSnackbar } = useSnackbar();
  const current_user = useSelector((state) => state.userReducer.current_user);
  const fields = [
    {
      key: "id",
      columnName: "ID",
      type: "text",
      form: false,
      visible: true,
    },
    {
        key: "langauge",
        columnName: "Language",
        label: "Language",
        required: true,
        visible: true,
        inputProps: {
          tabIndex: "10",
        },
        show: true,
      },
      {
        key: "symbol",
        columnName: "Symbol",
        width: 100,
        label: "Symbol",
        type: "file",
        hint: "Suggested file types: png, jpg, jpeg, gif",
        form: true,
        required: true,
        visible: true,
        render: (_, values) =>
          values.symbol && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.symbol}
              alt="Language Image"
            />
          ),
      },
];

const languageFields = [
  {
    key: "langauge",
    columnName: "Language",
    label: "Language",
    required: true,
    visible: true,
    freeSolo: false,
    inputProps: {
      tabIndex: "10",
    },
    show: true,
    type: "multiAutoComplete",
    options:  globalLanguageList?.length
    ? [...globalLanguageList.map(
      (x) => ({ label: x.langauge, value: x.langauge }))]
    : [{ label: "", value: null }]
  },
]

    const formFields = [
        {
            key: "langauge",
            columnName: "Language",
            label: "Language",
            required: true,
            visible: true,
            freeSolo: false,
            inputProps: {
              tabIndex: "10",
            },
            show: true,
            type: "autocomplete",
            options:  globalLanguageList?.length
            ? [...globalLanguageList.filter((x) => x.enabled).map(
              (x) => ({ label: x.langauge, value: x.langauge }))]
            : [{ label: "", value: null }]
          },
          {
            key: "symbol",
            columnName: "Symbol",
            width: 100,
            label: "Symbol",
            type: "file",
            hint: "Suggested file types: png, jpg, jpeg, gif",
            form: true,
            required: true,
            visible: true,
            render: (_, values) =>
              values.symbol && (
                <img
                  style={{ width: 84, height: 64 }}
                  src={values.symbol}
                  alt="Language Image"
                />
              ),
          },
      ];

  const setup = () => {
    setLoader(true);
    setLanguageList([]);
    setModulesSelected([]);
  };

  const getGlobalLanguages = async () => {
    window
      .axiosIns("/global_languages", { params: { all: true, enabled:true} })
      .then(({ data = {} }) => {
        console.log(data.data)
        console.log(data.data?.length
          ? [...data.data.filter((x) => x.enabled).map(
            (x) => ({ label: x.langauge, value: x.langauge }))]
          : [{ label: "", value: null }])
        setGlobalLanguageList(data.data);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get brands. Try again."
          );
        }
      });
  };

  const handleRes = (data) => {
    setLanguageList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getLanguages = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      state: 'all',
      ordering: order ? order : ordering,
      page: customPage + 1,
    };
    setup();
    const { data } = await window.axiosIns("/languages", {params});
    handleRes(data);
  };

  useEffect(() => {
    getGlobalLanguages();
    getLanguages();
  }, []);

const handleAdd = ({langauge, symbol}) => {
  const fd = new FormData();
  symbol && fd.append("symbol",symbol);
  fd.append("langauge", langauge);

  setIsMutating(true);
  setCrudLoader(true);
  window.axiosIns
  .post(`/languages`, fd)
  .then(() => {
    setCrudLoader(false);
    setAddModal(false);
    enqueueSnackbar("Language Added successfully.");
    setTimeout(() => {
      setIsMutating(false);
    }, 200);
  })
  .catch((err) => {
    if (err.response.data.detail) {
      enqueueSnackbar(err.response.data.detail);
    } else {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not add langauge. Try again."
      );
    }
    setCrudLoader(false);
    setIsMutating(false);
  })
  .then(() => {
    getLanguages();
    setModulesSelected([]);
  });
};

const handleSubmitEnableLanguage = (langauge) => {

  console.log(langauge)
  let payload = {
    languages: langauge.reduce((acc, language) => {
        acc[language.langauge] = language.enabled;
        return acc;
    }, {})
  };
  setIsMutating(true);
  setCrudLoader(true);
  window.axiosIns
  .post(`/global_languages/update_languages`, payload)
  .then(() => {
    setCrudLoader(false);
    setAddModal(false);
    enqueueSnackbar("Languages Updated successfully.");
    setTimeout(() => {
      setIsMutating(false);
    }, 200);
  })
  .catch((err) => {
    if (err.response.data.detail) {
      enqueueSnackbar(err.response.data.detail);
    } else {
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Update Languages. Try again."
      );
    }
    setCrudLoader(false);
    setIsMutating(false);
  })
  .then(() => {
    getGlobalLanguages();
    setModulesSelected([]);
    setEnableModal(false);
  });
};

const handleEdit = ({langauge, symbol}) => {
  const fd = new FormData();
  symbol && symbol.type && fd.append("symbol",symbol);
  fd.append("langauge", langauge);

  const moduleId = modulesSelected[0].id;
  setIsMutating(true);
  setCrudLoader(true);
  window.axiosIns
  .put(`/languages/${moduleId}`, fd)
    .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Language edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
    }).catch(err => {
        if (err.detail) {
            enqueueSnackbar(err.detail)
        }
        else if (((err.response || {}).data || {}).detail) {
            enqueueSnackbar(((err.response || {}).data || {}).detail)
        }
        else {
            handleServerErrors(err, enqueueSnackbar, "Could not edit langauge. Try again.");
        }
        setCrudLoader(false);
        setEditModal(false);
        setIsMutating(false);
    }).then(() => {
        setLanguageList([])
        setModulesSelected([]);
        getLanguages();
    })
};

const handleFilter = (arr) => {
  setFilterable(arr);
  if (query !== "") {
    let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
    setup();
    setPage(0);
    window
      .axiosIns("/languages", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          ordering,
        }
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  }
};


const changePage = async(url) => {
  setup();
  const { data } = await window.axiosIns(url);
handleRes(data);
};

const handleSearch = (value) => {
  setQuery(value);
  let searchFilter = {};
  if (value !== "") {
    searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
  }
  setSearchQuery(searchFilter);
  setup();
  setPage(0);
  window
    .axiosIns("/languages", {
      params: {
        ...searchFilter,
        limit: rowsPerPage,
        state: 'all',
        ordering,
      }
    })
    .then((data = {}) => {
      handleRes(data.data);
    })
    .catch((err) => {
      setLoader(false);
    });
};

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
    window.axiosIns
    .delete(`/languages/${val.id}`)
            .then(() => {
                setDeleteModal(false);
                if(modulesSelected.length === index + 1) {
                    if(languageList.length - modulesSelected.length === 0 && page > 0) {
                        setPage(page - 1);
                        changePage(previousPage);
                    } else {
                        getLanguages();
                    }
                    enqueueSnackbar("Language deleted successfully.");
                }
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else if(err.response.data.detail){
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not delete language. Try again.");
                }
            })
    });
  };

  const handleEnable = () => {
    modulesSelected.forEach((val, index) => {
    window.axiosIns
    .delete(`/languages/${val.id}`)
            .then(() => {
                setDeleteModal(false);
                if(modulesSelected.length === index + 1) {
                    if(languageList.length - modulesSelected.length === 0 && page > 0) {
                        setPage(page - 1);
                        changePage(previousPage);
                    } else {
                        getLanguages();
                    }
                    enqueueSnackbar("Language deleted successfully.");
                }
            }).catch(err => {
                if (err.detail) {
                    enqueueSnackbar(err.detail);
                }
                else if(err.response.data.detail){
                    enqueueSnackbar(err.response.data.detail)
                }
                else {
                    handleServerErrors(err, enqueueSnackbar, "Could not delete language. Try again.");
                }
            })
    });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Brands"
        description="All Languages are listed here. Language Variants can be added here to associate languages to specific languages."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === 'SU' || 
              globalLanguageList.length == languageList.length}
            
          />
          <EditButton
            disabled={modulesSelected.length !== 1 || current_user.type === 'SU'}
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={modulesSelected.length === 0 || current_user.type === 'SU'}
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {/* {console.log("Current USer", current_user.type)} */}
          {current_user.type==='SA'&&
          <AllocateButton
            className="mr-3"
            label="Enable Languages"
            onClick={() => setEnableModal(true)}
          />}
        </div>
        <div className="d-flex">
          <SearchBox
           placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={languageList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getLanguages(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getLanguages(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
        <CrudDialog
          title="Add Language"
          okText="Add Language"
          fields={formFields}
          submitButtonDisabled={isMutating}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
        />
        <CrudDialog
          title="Edit Language"
          okText="Save"
          submitButtonDisabled={isMutating}
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={formFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
        />
        <CrudDialog
          title="Delete Language"
          description="Are you sure you want to delete the language?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      <LanguageSelectionDialog
        open={enableModal}
        onClose={()=>setEnableModal(false)}
        onSubmit={handleSubmitEnableLanguage}
        languages={globalLanguageList}
      />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(Languages);
