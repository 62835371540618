import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

function LanguageSelectionDialog(props) {
  const { open, onClose, onSubmit, languages, classes } = props;
  const [globalLanguages, setGlobalLanguages] = useState([]);


  useEffect(() => {

    if (open) {
      setGlobalLanguages(languages);
    }
  }, [open, languages]);

  const handleToggle = (id) => (event) => {
    setGlobalLanguages((prev) =>
      prev.map((lang) => {
        if (lang.id === id) {
          return { ...lang, enabled: event?.target?.checked }
        } else { return lang }
      }

      )
    );
  };

  const handleSubmit = () => {
    onSubmit(globalLanguages);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {console.log("Languages", languages)}
        <Typography variant="h6">Select Languages</Typography>
        <Typography variant="body2">Please enable or disable the languages you need.</Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          {[0, 1, 2].map((columnIndex) => (
            <Grid item xs={4} key={columnIndex} className={classes.column}>
              {globalLanguages
                .filter((_, index) => index % 3 === columnIndex)
                .map((language) => (
                  <FormControlLabel
                    key={language.id}
                    control={
                      <Checkbox
                        checked={language.enabled}
                        onChange={handleToggle(language.id)}
                        color="primary"
                      />
                    }
                    label={language.langauge}
                  />
                ))}
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(LanguageSelectionDialog);
